import React, { useState } from 'react';

import DocumentationContent from './DocumentationContent';
import DocumentationSidebar from './DocumentationSidebar';
import Footer from "../components/Footer";

function Documentation() {

  // const [sidebarNavOpen, setSidebarNavOpen] = useState(false);
  // const [sidebarLinkOpen, setSidebarLinkOpen] = useState(true);
  // const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  // {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  // {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  // {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  // {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  // {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  // {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];

  // const images2 = [{title: "Photon", src: image}];
  return (      
    <div>
          <main className="flex-grow">

          <section>
            <div className="max-w-7xl mx-auto px-2 sm:px-2">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                {/* Main content */}
                <div className="md:flex md:justify-between" data-sticky-container>

                  <DocumentationSidebar />

                  {/* Page container */}
                  <DocumentationContent />

                </div>

              </div>
            </div>
          </section>

          </main>
        <Footer />

    </div>



  );
}

export default Documentation;