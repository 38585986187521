import * as React from "react"
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import {Image} from "lightbox.js-react";

export default function Component () {
  const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  return <Layout>
  <section className="section section--gradient font-inter bg_white">
    <Header />
  
      <div className="pt-4">
      <main className="flex-grow">

          <section>
          <div className="max-w-6xl mx-auto px-2 sm:px-2">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Main content */}
              <div className="md:flex md:justify-between" data-sticky-container>

                  <DocumentationSidebar />

                  <div className="md:flex-grow documentation font-inter">

                    {/* <Header /> */}
                    <div className="text-lg text-gray-600">
                      <h1 className="h1 text-gray-900 mb-4 font-bold">Video Lightbox</h1>

                      <p className="mb-8">
                      This component displays a video lightbox. For displaying multiple images in a slideshow format, see the Slideshow component instead.

                      </p>
                      
                      <p className="list_desc">The following features are supported:</p>

                      <ul className="list-disc list ml-8">
                          <li><b>Zooming:</b> Users can zoom through: 
                            <ul className="list-disc">
                              <li>Pinch-to-zoom</li>
                              <li>Mouse wheel</li>
                              <li>Single-click</li>
                            </ul>
                          </li>
                          <li> <b>Panning:</b> Once an image is zoomed into, the image can be panned by dragging the image through the mouse, or if on a mobile device, with a swipe-to-drag motion.</li>
                          <li><b>Image drag:</b> Images can be navigated from one image to the next by dragging the image using the mouse or with a swipe-to-drag motion.</li>

                    </ul>

                    <article className="mb-8 mt-4">
                      <h3 id="import" className="h3 text-gray-900 mb-4 text-3xl font-bold">Import</h3>
                      <div style={{fontFamily: 'Fira Code'}}>
                        <CopyBlock
                          text={`import {Image} from "lightbox.js";`}
                          language={'jsx'}
                          theme={atomOneDark}
                          {...{ showLineNumbers, wrapLines, codeBlock }}
                        /> 
                      </div>

                    </article>

                  <h3 id="installation" className="text-gray-900 mb-4 text-3xl font-semibold" style={{ scrollMarginTop: '100px' }}>Usage</h3>
                  <p className="mb-8">
                    To include an image that displays a lightbox when clicked, simply add the following:
                  </p>
                  <div style={{fontFamily: 'Fira Code'}}>
                       <CopyBlock
                        text={`<Image image={image} />`}
                        language={'jsx'}
                        theme={atomOneDark}
                        {...{ showLineNumbers, wrapLines, codeBlock }}
                      /> 
                    </div>

                    <p className="mb-4 mt-8">
                    This will render an image as shown below. Try clicking it to see the animation in action:
                    </p>

                    <Image image={images[0]} />

                    <p className="flex mt-8 items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
                      <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
                      </svg>
                      <span>Check out the <a className="text-blue-600 hover:underline" href="#props">SlideshowAnim</a> component to display multiple images in a slideshow format.</span>
                    </p>

                    <h3 className="h3">Varying Animations</h3>
            
                    <p>If you'd prefer no animation, then this can be switched off also. </p>

                    <h3>Available Themes</h3>

                    <p>There are 3 themes available, which can be selected via setting the `theme` prop:</p>

                    <ul className="list-disc list ml-8">
                        <li><b>day</b>: A light theme with a white background and gray icons</li>
                        <li><b>night</b>: A dark theme with gray icons</li>
                        <li><b>lightbox</b>: A theme with a semi-transparent gray background</li>
                    </ul>

                    <h4>Day theme example</h4>
                    <Image image={images[0]} theme="day"/>

                    <h4>Night theme example</h4>
                    <Image image={images[0]} theme="night"/>

                    <h4>Lightbox theme example</h4>
                    <Image image={images[0]} theme="lightbox" />

                    <article className="mb-8 mt-8">
                      <h2 className="h2 text-gray-900 mb-4 text-3xl font-semibold">Customization</h2>

                      <h4>Background Color</h4>
                      <p>
                        If you'd like to customize the background color of the lightbox, this can be done by passing a color 
                        to the <code>color</code> prop, as a RGB, RGBA, HEX or CSS color name value:
                      </p>
                      
                      <h4>Icon Color</h4>
                      <p>
                        The icon colors can also be specified through the `iconColor` prop, and the color can be a RGB, 
                        RGBA, HEX or CSS value.
                      </p>

                      <h4>Image Animation</h4>
                      <p className="list-desc">
                        The animation type can be specified through the `imgAnimation` prop, and the two values include:
                      </p>

                        <ul className="list list-disc ml-8">
                          <li>imgMotion</li>
                          <li>fade</li> 
                        </ul>

                      <div style={{fontFamily: 'Fira Code'}}>
                        {/* <CopyBlock
                          text={`<CircularCheckbox color={'blue'} />\n<CircularCheckbox color={'rgb(234, 120, 210)'} />\n<CircularCheckbox color={'#f06'} />`}
                          language={'jsx'}
                          theme={atomOneDark}
                          {...{ showLineNumbers, wrapLines, codeBlock }}
                        /> */}
                      </div>

      </article>


      <h2 id="full_example" className="h2" style={{ scrollMarginTop: '100px' }}>Full Example</h2>
      <p className="mb-8">
        A full example is shown below, where the component is imported and included in the React component:
      </p>

      <div style={{fontFamily: 'Fira Code'}}>

        </div>

      <h3 id="quick" className="h3 text-gray-900 mb-8 font-semibold" style={{ scrollMarginTop: '100px' }}>Props</h3>
      <h4 className="h4 text-gray-900 mb-4 font-bold">theme</h4>

      <p className="mb-8">
        <b>theme</b>: String - The theme to be applied to the lightbox. Options include day, night, lightbox
      </p>

      <h4 className="h4 text-gray-900 mb-4 font-bold">backgroundColor</h4>
      <p className="mb-8">
        <b>backgroundColor</b>: String - the background color of the lightbox, as a CSS color name, RGBA value or HEX code
      </p>

      <h4 className="h4 text-gray-900 mb-4 font-bold">iconColor</h4>
      <p className="mb-8">
        <b>iconColor</b>: String - the icon color for the lightbox, as a CSS color name, RGBA value or HEX code
      </p>

    </div>

    {/* Related content */}
    <div className="mt-8">
      <h3 className="h3 mb-8 font-semibold">Related</h3>
      <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
        <div>
          <div className="text-normal font-medium mb-1">Folder components</div>
          <div className="text-sm text-gray-600">Learn more about orci ac auctor augue mauris augue neque gravida.</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
        <div>
          <div className="text-normal font-medium mb-1">Deploy hooks</div>
          <div className="text-sm text-gray-600">Learn more about risus nullam eget felis eget nunc.</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
    </div>

    {/* <Feedback /> */}

  </div>

              </div>

              </div>
          </div>
          </section>

          </main>
      </div>

  </section>
  </Layout>
}